import '../css/app.css'
import { createInertiaApp } from '@inertiajs/vue3'
import { resolvePageComponent } from 'laravel-vite-plugin/inertia-helpers'
import { modal } from '@vendor/emargareten/inertia-modal'
import { createApp, DefineComponent, h } from 'vue'
import { ZiggyVue } from 'ziggy-js'
import { createPinia } from 'pinia'
import * as Sentry from '@sentry/vue'

const appName = import.meta.env.VITE_APP_NAME || 'Laravel'
const pinia = createPinia()

createInertiaApp({
    title: title => `${title} - ${appName}`,
    resolve: name =>
        resolvePageComponent(`./Pages/${name}.vue`, import.meta.glob('./Pages/**/*.vue')) as Promise<DefineComponent>,
    setup({ el, App, props, plugin }) {
        const app = createApp({ render: () => h(App, props) })

        Sentry.init({
            app: app,
            dsn: import.meta.env.VITE_SENTRY_DSN_PUBLIC,
            integrations: [Sentry.browserTracingIntegration(), Sentry.replayCanvasIntegration()],
            tracesSampleRate: 0.2,
            tracePropagationTargets: [/^https:\/\/hub\.spsgrupp\.ee/],
            replaysSessionSampleRate: 0.1,
            replaysOnErrorSampleRate: 1.0,
        })

        app.use(plugin)
            .use(modal, {
                resolve: name => resolvePageComponent(`./Pages/${name}.vue`, import.meta.glob('./Pages/**/*.vue')),
            })
            .use(ZiggyVue)
            .use(pinia)
            .mount(el)

        return app
    },
    progress: {
        color: '#4B5563',
    },
})
